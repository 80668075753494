// React y Hooks
import { useState, useEffect } from 'react';

// Librerías externas
import AOS from 'aos';
import { Swiper, SwiperSlide } from 'swiper/react';

// Estilos
import 'aos/dist/aos.css';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import './App.css';

// Módulos de Swiper
import { Navigation, Pagination, Autoplay } from 'swiper/modules';

// Assets - Videos
import introVideoMobile from './assets/intro3.mp4';
import introVideoDesktop from './assets/intro4.mp4';

// Assets - Imágenes principales
import heroImage1 from './assets/Hero2.jpg';
import heroImage2 from './assets/Hero2.jpg';
import heroImage3 from './assets/Hero2.jpg';

// Assets - Imágenes del carrusel
import carouselImage1 from './assets/C1.png';
import carouselImage3 from './assets/C3.png';
import carouselImage5 from './assets/C5.jpg';
import carouselImage6 from './assets/C6.jpg';
import carouselImage7 from './assets/C7.jpg';

// Assets - Íconos
import providerIcon from './assets/contact.svg';
import clockIcon from './assets/time-pending.svg';
import upIcon from './assets/up.svg';
import secureIcon from './assets/reembolso.svg';
import supportIcon from './assets/support.svg';
import cleanIcon from './assets/clean.svg';

// Assets - Otros
import loadingImg from './assets/logoMoving.gif';
import logo from './assets/logo3.svg';
import TopBanner from './components/TopBanner/TopBanner';

const heroImages = [heroImage1, heroImage2, heroImage3];
const carouselImages = [
  carouselImage6,
  carouselImage5,
  carouselImage3,
  carouselImage1,
  carouselImage7,
];

function App() {
  const [showLanding, setShowLanding] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [showIntro, setShowIntro] = useState(false);
  const [slidesPerView, setSlidesPerView] = useState(3);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [showScrollButton, setShowScrollButton] = useState(false);
  const [backgroundActive, setBackgroundActive] = useState(false);

  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  useEffect(() => {
    if (showIntro) {
      const timer = setTimeout(
        () => {
          setIsMobile(window.innerWidth <= 768);
          setBackgroundActive(true);
        },
        window.innerWidth <= 768 ? 1470 : 1420
      );

      return () => clearTimeout(timer);
    }
  }, [showIntro]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setSlidesPerView(window.innerWidth <= 768 ? 1 : window.innerWidth <= 1324 ? 2 : 3);
    };

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const hasSeenIntro = localStorage.getItem('hasSeenIntro') === 'true';

    if (!hasSeenIntro) {
      localStorage.setItem('hasSeenIntro', 'true');
      setShowIntro(true);

      setTimeout(() => {
        setShowIntro(false);
        setShowLanding(true);
      }, 4000);
    } else {
      setTimeout(() => {
        setShowLanding(true);
      }, 1500);
    }
  }, []);

  useEffect(() => {
    if (showLanding) {
      const carouselInterval = setInterval(() => {
        setCurrentImage((prev) => (prev + 1) % heroImages.length);
      }, 4000);
      return () => clearInterval(carouselInterval);
    }
  }, [showLanding]);

  useEffect(() => {
    const handleScroll = () => {
      setShowScrollButton(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="App" style={{ maxWidth: showIntro ? '100%' : '1600px' }}>
      {showIntro ? (
        <div className={`video-container ${backgroundActive ? 'video-bg-active' : ''}`}>
          <video
            autoPlay
            muted
            playsInline
            className="intro-video"
            onEnded={() => setShowLanding(true)}
          >
            <source src={isMobile ? introVideoMobile : introVideoDesktop} type="video/mp4" />
            Tu navegador no soporta el elemento de video.
          </video>
        </div>
      ) : showLanding ? (
        <>
          <header className="App-header">
            <TopBanner />
            <div className="nav-bar">
              <img src={logo} alt="Logo" className="logo" />
              <a className="nav-btn" href="https://beta-appcleanbetternow.netlify.app">
                Iniciar sesión
              </a>
            </div>
            <div
              className="hero-section"
              data-aos="fade-down"
              data-aos-duration="1000"
              style={{ backgroundImage: `url(${heroImages[currentImage]})` }}
            >
              <div className="hero-bg">
                <h1 className="hero-title">Limpieza sin esfuerzo al alcance de tu mano</h1>
                <p className="hero-subtitle">
                  Encuentra y reserva servicios de limpieza de confianza en solo unos clics.
                </p>
                <button className="hero-cta">
                  <img src={cleanIcon} alt="Clean Icon" />
                  <a className="hero-link" href="https://beta-appcleanbetternow.netlify.app">
                    Registrarse
                  </a>
                </button>
              </div>
            </div>
          </header>
          <section className="features-section">
            <div className="feature" data-aos="fade-down" data-aos-duration="1000">
              <img src={providerIcon} alt="Mejores Proveedores" className="feature-icon" />
              <h2 className="feature-title">Mejores Proveedores</h2>
              <p>
                Accede a una lista seleccionada de los mejores profesionales de limpieza en tu área.
              </p>
            </div>
            <div className="feature" data-aos="fade-down" data-aos-duration="1100">
              <img src={clockIcon} alt="Horarios Flexibles" className="feature-icon" />
              <h2 className="feature-title">Horarios Flexibles</h2>
              <p>Reserva servicios en el horario que más te convenga sin complicaciones.</p>
            </div>
            <div className="feature" data-aos="fade-down" data-aos-duration="1200">
              <img src={secureIcon} alt="Pagos Seguros" className="feature-icon" />
              <h2 className="feature-title">Pagos Seguros</h2>
              <p>Paga de forma segura a través de nuestra plataforma y disfruta de tranquilidad.</p>
            </div>
          </section>
          <section className="carousel-section">
            <Swiper
              modules={[Navigation, Pagination, Autoplay]}
              spaceBetween={50}
              slidesPerView={slidesPerView}
              navigation
              pagination={{ clickable: true }}
              autoplay={{ delay: 3000, disableOnInteraction: false }}
              loop={true}
            >
              {carouselImages.map((image, index) => (
                <SwiperSlide key={index} className="carousel-slide">
                  <img src={image} alt={`Carousel ${index}`} className="carousel-image" />
                </SwiperSlide>
              ))}
            </Swiper>
          </section>
          <footer className="footer" data-aos="fade-up">
            <div className="flex-cls">
              <img src={supportIcon} alt="Pagos Seguros" className="support-icon" />
              <p className="link-cls">support@cleanbetternow.com</p>
            </div>
            <p>
              &copy; 2025 Clean.Better.Now <br /> Todos los derechos reservados.
            </p>
          </footer>
          {showScrollButton && (
            <button className="scroll-to-top" data-aos="fade-up" onClick={scrollToTop}>
              <img src={upIcon} alt="Scroll to top" />
            </button>
          )}
        </>
      ) : (
        <div
          className="loading-screen"
          style={{ backgroundImage: `url(${heroImages[currentImage]})` }}
        >
          <img src={loadingImg} alt="Cargando CBN" className="loader-icon" data-aos="fade-right" />
        </div>
      )}
    </div>
  );
}

export default App;
