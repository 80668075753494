import { useEffect, useState } from 'react';
import './TopBanner.css';
import exitIcon from '../../assets/exit.svg';

const TopBanner = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const bannerClosed = localStorage.getItem('bannerClosed');
    console.log('bannerClosed', bannerClosed);
    if (!bannerClosed) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    localStorage.setItem('bannerClosed', 'true');
  };

  if (!isVisible) return null;

  return (
    <div className="top-banner">
      <p className="banner-text">
        🎉 ¡Oferta especial! Obtén un 20% de descuento en tu{' '}
        <a
          onClick={handleClose}
          className="link-cls"
          href="https://beta-appcleanbetternow.netlify.app"
        >
          primer servicio
        </a>
        .
      </p>
      <button className="close-btn" onClick={handleClose}>
        <img src={exitIcon} alt="Exit Banner" />
      </button>
    </div>
  );
};

export default TopBanner;
